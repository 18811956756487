<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              이메일 답변 신청자 수 [{{ totalRecords }}] 건
            </h5>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-dark"
              class="btn-search"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
              />
              <span> 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        primary-key="commentId"
        :items="emailAskList"
        :fields="tableColumns"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
      >
        <template #cell(createDate)="data">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <span class="text-nowrap">
              {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
            </span>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { useToast } from "vue-toastification/composition"
import {
  ref, watch,
} from '@vue/composition-api'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"

export default {
  components: {
  },
  props: {
    liveInfoId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const query = ref({
      liveInfoId: null,
    })

    watch(() => props.liveInfoId, newVal => {
      query.value.liveInfoId = newVal
    })
    watch(query.value, () => {
      fetchEmailAsks()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const totalRecords = ref(0)
    const emailAskList = ref([])

    const tableColumns = [
      {
        key: "id", label: "ID", sortable: true, thStyle: { width: '10%' },
      },
      {
        key: "fullName", label: "의사명", sortable: true, thStyle: { width: '7%' },
      },
      {
        key: "hcpWorkPlace", label: "의원명", sortable: true, thStyle: { width: '10%' },
      },
      {
        key: "hcpWorkAddress", label: "의원주소", sortable: true, thStyle: { width: '20%' },
      },
      {
        key: "drugAskDetail", label: "문의 내역", sortable: true,
      },
      {
        key: "email", label: "이메일 주소", sortable: true, thStyle: { width: '15%' },
      },
      {
        key: "createDateStr", label: "신청 일시", sortable: true, thStyle: { width: '15%' },
      },
    ]

    const fetchEmailAsks = () => {
      if (query.value.liveInfoId) {
        axios.post('/cl/druginfo/email', {
          search: {
            query: query.value,
          },
          sort: {
            predicate: 'createDate',
            reverse: true,
          },
        })
          .then(rs => {
            const { items, totalRecord } = rs.data
            emailAskList.value = items
            totalRecords.value = totalRecord
          })
          .catch(() => {
            pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
          })
      }
    }

    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "ID", key: "id", width: 10 },
        { header: "의사명", key: "fullName", width: 10 },
        { header: "의원명", key: "hcpWorkPlace", width: 50 },
        { header: "의원주소", key: "hcpWorkAddress", width: 60 },
        { header: "문의내역", key: "drugAskDetail", width: 60 },
        { header: "이메일 주소", key: "email", width: 20 },
        { header: "신청 일시", key: "createDateStr", width: 20 },
      ]

      emailAskList.value.map(e => worksheet.addRow(e))

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })
      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], mimeType)
      saveAs(blob, "이메일 답변 신청 내역.xlsx")
    }

    return {
      tableColumns,
      totalRecords,
      emailAskList,
      excelDownload,
    }
  },
}
</script>

<style>
</style>
