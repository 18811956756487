import { render, staticRenderFns } from "./DrugInfoClickView.vue?vue&type=template&id=e946cde2&"
import script from "./DrugInfoClickView.vue?vue&type=script&lang=js&"
export * from "./DrugInfoClickView.vue?vue&type=script&lang=js&"
import style0 from "./DrugInfoClickView.vue?vue&type=style&index=0&id=e946cde2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("d:\\workspace\\liveinfo3-front-admin-dev\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e946cde2')) {
      api.createRecord('e946cde2', component.options)
    } else {
      api.reload('e946cde2', component.options)
    }
    module.hot.accept("./DrugInfoClickView.vue?vue&type=template&id=e946cde2&", function () {
      api.rerender('e946cde2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/clients/statistics/durginfo-stast-view/DrugInfoClickView.vue"
export default component.exports