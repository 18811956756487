<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="제약사"
            >
              <b-form-input
                v-model="drugInfoData.clientName"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="제목"
            >
              <b-form-input
                v-model="drugInfoData.title"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="drugInfoData.drugInfoType === 1"
            cols="12"
            md="4"
          >
            <b-form-group
              label="컨텐츠"
              label-for=""
            >
              <b-button
                variant="primary"
                class="mr-sm-50"
                @click="showModal = true"
              >
                <span>미리보기</span>
              </b-button>
              <b-modal
                id="drugInfoPreview"
                v-model="showModal"
                title="HTML 미리보기"
              >
                <iframe
                  ref="previewFrame"
                  :srcdoc="drugInfoData.htmlContent"
                  frameborder="0"
                  max-width="1150px"
                  width="1115px"
                  height="1000px"
                  style="background-color: white;"
                />
              </b-modal>
            </b-form-group>
          </b-col>
          <b-col
            v-if="drugInfoData.drugInfoType === 2"
            cols="12"
            md="4"
          >
            <b-form-group
              label="미리보기"
              label-for=""
            >
              <b-button
                v-if="drugInfoData.tabContent1"
                variant="primary"
                class="mr-sm-50"
                @click="detailTabView1 = true"
              >
                <span>컨텐츠1</span>
              </b-button>
              <b-modal
                id="drugInfoPreview"
                v-model="detailTabView1"
                title="HTML 미리보기"
              >
                <iframe
                  ref="previewFrame"
                  :srcdoc="drugInfoData.tabContent1"
                  frameborder="0"
                  max-width="1150px"
                  width="1115px"
                  height="1000px"
                  style="background-color: white;"
                />
              </b-modal>
              <b-button
                v-if="drugInfoData.tabContent2"
                variant="primary"
                class="mr-sm-50"
                @click="detailTabView2 = true"
              >
                <span>컨텐츠2</span>
              </b-button>
              <b-modal
                id="drugInfoPreview"
                v-model="detailTabView2"
                title="HTML 미리보기"
              >
                <iframe
                  ref="previewFrame"
                  :srcdoc="drugInfoData.tabContent2"
                  frameborder="0"
                  max-width="1150px"
                  width="1115px"
                  height="1000px"
                  style="background-color: white;"
                />
              </b-modal>
              <b-button
                v-if="drugInfoData.tabContent3"
                variant="primary"
                class="mr-sm-50"
                @click="detailTabView3 = true"
              >
                <span>컨텐츠3</span>
              </b-button>
              <b-modal
                id="drugInfoPreview"
                v-model="detailTabView3"
                title="HTML 미리보기"
              >
                <iframe
                  ref="previewFrame"
                  :srcdoc="drugInfoData.tabContent3"
                  frameborder="0"
                  max-width="1150px"
                  width="1115px"
                  height="1000px"
                  style="background-color: white;"
                />
              </b-modal>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="처방 팝업 조건(약품)"
            >
              <b-form-input
                v-model="drugInfoData.condDrugCodes"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="처방 팝업 조건(질병)"
            >
              <b-form-input
                v-model="drugInfoData.condDiseaseCodes"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="처방 팝업 조건(검사)"
            >
              <b-form-input
                v-model="drugInfoData.condLabCodes"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="컨텐츠 게시 기간(Fr)"
            >
              <b-form-datepicker
                v-model="drugInfoData.startDate"
                placeholder="게시기간(Fr)"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="컨텐츠 게시 기간(To)"
            >
              <b-form-datepicker
                v-model="drugInfoData.endDate"
                placeholder="게시기간(Fr)"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="모아보기 게시 기간(Fr)"
            >
              <b-form-datepicker
                v-model="drugInfoData.moaStartDate"
                placeholder="게시기간(Fr)"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="모아보기 게시 기간(Fr)"
            >
              <b-form-datepicker
                v-model="drugInfoData.moaEndDate"
                placeholder="게시기간(Fr)"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="처방팝업 게시 기간(Fr)"
            >
              <b-form-datepicker
                v-model="drugInfoData.ipuStartDate"
                placeholder="게시기간(Fr)"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="처방팝업 게시 기간(To)"
            >
              <b-form-datepicker
                v-model="drugInfoData.ipuEndDate"
                placeholder="게시기간(Fr)"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="스케줄팝업 게시 기간(Tr)"
            >
              <b-form-datepicker
                v-model="drugInfoData.spuStartDate"
                placeholder="게시기간(Fr)"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="스케줄팝업 게시 기간(To)"
            >
              <b-form-datepicker
                v-model="drugInfoData.spuEndDate"
                placeholder="게시기간(Fr)"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-col
          cols="12"
          md="4"
        />
        <!-- <b-row>
          <b-col
            cols="12"
            md="1"
          >
            <b-form-group
              label="댓글 수"
            >
              <b-form-input
                v-model="drugInfoData.commentCount"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="1"
          >
            <b-form-group
              label="팝업 노출 수"
            >
              <b-form-input
                v-model="drugInfoData.popupNotiCnt"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="1"
          >
            <b-form-group
              label="팝업 클릭 수"
            >
              <b-form-input
                v-model="drugInfoData.popupClickCnt"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="1"
          >
            <b-form-group
              label="총 클릭 수"
            >
              <b-form-input
                v-model="drugInfoData.viewCount"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row> -->
      </div>
    </b-card>
    <b-card no-body>
      <b-tabs
        pills
        class="m-2"
      >
        <b-tab active>
          <template #title>
            <feather-icon
              icon="MessageSquareIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">댓글 상세 정보</span>
          </template>
          <comment-info-view :live-info-id="liveInfoId" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">방문 신청 상세 정보</span>
          </template>
          <visit-ask-view :live-info-id="liveInfoId" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="GiftIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">샘플 신청 상세 정보</span>
          </template>
          <sample-ask-view :live-info-id="liveInfoId" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="EditIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">이메일 답변 신청 상세 정보</span>
          </template>
          <email-ask-view :live-info-id="liveInfoId" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="Share2Icon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">통계 상세 정보</span>
          </template>
          <drugInfo-click-View
            :live-info-id="liveInfoId"
            :start-date="new Date(drugInfoData.startDate)"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>

import axios from '@axios'
import { BTab, BTabs } from 'bootstrap-vue'
import { ref, onMounted } from "@vue/composition-api"
import { getUserData } from '@/auth/utils'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { useToast } from "vue-toastification/composition"
import commentInfoView from './CommentInfoView.vue'
import visitAskView from './VisitAskView.vue'
import sampleAskView from './SampleAskView.vue'
import emailAskView from './EmailAskView.vue'
import drugInfoClickView from './DrugInfoClickView.vue'

export default {
  components: {
    BTab,
    BTabs,
    commentInfoView,
    visitAskView,
    sampleAskView,
    emailAskView,
    drugInfoClickView,
  },
  computed: {

  },
  setup() {
    onMounted(() => {
      fetchDrugInfo()
    })
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const drugInfoData = ref({})
    const accessCode = ref(null)
    const liveInfoId = ref(0)

    /// 미리보기 관련
    const showModal = ref(false)
    const detailTabView1 = ref(false)
    const editorModal1 = ref(false)
    const detailTabView2 = ref(false)
    const editorModal2 = ref(false)
    const detailTabView3 = ref(false)
    const editorModal3 = ref(false)

    const fetchDrugInfo = () => {
      const userData = getUserData()
      accessCode.value = userData.accessCode
      axios.get(`/cl/druginfo/${accessCode.value}`)
        .then(rs => {
          const { data } = rs
          drugInfoData.value = data
          liveInfoId.value = data.liveInfoId
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    return {
      drugInfoData,
      liveInfoId,
      showModal,
      detailTabView1,
      editorModal1,
      detailTabView2,
      editorModal2,
      detailTabView3,
      editorModal3,
    }
  },
}
</script>

<style lang="scss">
#drugInfoPreview .modal-dialog {
  max-width: 1150px;
}
</style>
