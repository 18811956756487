var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("h5", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(
                          " 댓글 수 [" + _vm._s(_vm.totalRecords) + "] 건 "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-search",
                          attrs: { variant: "outline-dark" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.excelDownload()
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "DownloadIcon" },
                          }),
                          _c("span", [_vm._v(" 다운로드")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "primary-key": "commentId",
              items: _vm.commentList,
              fields: _vm.tableColumns,
              responsive: "",
              "sticky-header": "",
              selectable: "",
              "select-mode": "single",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(createDate)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "justify-content": "center",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("span", { staticClass: "text-nowrap" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("$dateFormatter")(
                                  data.value,
                                  "YYYY-MM-DD HH:mm"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }