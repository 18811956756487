var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table-simple",
        {
          staticStyle: { "max-height": "65vh", "font-size": "12px" },
          attrs: { bordered: "", responsive: "", "sticky-header": "" },
        },
        [
          _c("colgroup", [
            _c("col", { staticStyle: { width: "130px" } }),
            _c("col", { staticStyle: { width: "150px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "120px" } }),
          ]),
          _c(
            "b-thead",
            { attrs: { "head-variant": "light" } },
            [
              _c(
                "b-tr",
                [
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "0" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 조회일자 ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "95px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 진료과 ")]
                  ),
                  _c("b-th", { attrs: { colspan: "3" } }, [
                    _vm._v(" 모아보기 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "4" } }, [
                    _vm._v(" 처방팝업 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "4" } }, [
                    _vm._v(" 스케줄팝업 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "2" } }, [
                    _vm._v(" 닥터인포 "),
                  ]),
                  _c("b-th", { attrs: { rowspan: "2" } }, [
                    _vm._v(" 총 클릭 수 "),
                  ]),
                  _c("b-th", { attrs: { rowspan: "2" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "총 클릭 처수" : "총 클릭 명수"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 노출 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "클릭 처수" : "클릭 명수"
                        ) +
                        " "
                    ),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 노출 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "노출 처수" : "노출 명수"
                        ) +
                        " "
                    ),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "클릭 처수" : "클릭 명수"
                        ) +
                        " "
                    ),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 노출 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "노출 처수" : "노출 명수"
                        ) +
                        " "
                    ),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "클릭 처수" : "클릭 명수"
                        ) +
                        " "
                    ),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "클릭 처수" : "클릭 명수"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.data
            ? _c(
                "b-tbody",
                [
                  _vm._l(_vm.data.statistics, function (detail) {
                    return [
                      _vm._l(detail.detailDepts, function (member, index) {
                        return [
                          _c(
                            "b-tr",
                            { key: "member-" + detail.yyyymmdd + "-" + index },
                            [
                              index === 0
                                ? _c(
                                    "b-td",
                                    {
                                      staticClass: "sticky-row",
                                      staticStyle: { left: "0" },
                                      attrs: {
                                        rowspan:
                                          detail.detailDepts.length > 0
                                            ? detail.detailDepts.length
                                            : 0,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("$dateFormatter")(
                                              detail.yyyymmdd,
                                              "YYYY-MM-DD"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "95px" },
                                },
                                [_vm._v(" " + _vm._s(member.medicalDept) + " ")]
                              ),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.totalMoaOpenCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.totalMoaClickCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.groupMoaClickCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.totalPrescriptionOpenCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.groupPrescriptionOpenCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.totalPrescriptionClickCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.groupPrescriptionClickCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.totalScheduleOpenCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.groupScheduleOpenCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.totalScheduleClickCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.groupScheduleClickCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.totalDrInfoClickCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.groupDrInfoClickCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.totalClickCount
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      member.groupClickCount
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      }),
                      detail.detailDepts.length > 0
                        ? _c(
                            "b-tr",
                            { key: "member-total-" + detail.yyyymmdd },
                            [
                              _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: {
                                    "text-align": "center",
                                    left: "0px",
                                  },
                                  attrs: { colspan: "2" },
                                },
                                [_vm._v(" 소계 ")]
                              ),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.totalMoaOpenCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.totalMoaClickCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.groupMoaClickCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.totalPrescriptionOpenCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.groupPrescriptionOpenCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.totalPrescriptionClickCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.groupPrescriptionClickCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.totalScheduleOpenCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.groupScheduleOpenCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.totalScheduleClickCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.groupScheduleClickCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.totalDrInfoClickCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.groupDrInfoClickCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.totalClickCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      detail.detailDepts.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.groupClickCount
                                      },
                                      0)
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                  _c(
                    "b-tr",
                    [
                      _c(
                        "b-td",
                        {
                          staticClass: "sticky-row",
                          staticStyle: { "text-align": "center", left: "0px" },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v(" 합계 ")]
                      ),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.totalMoaOpenCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.totalMoaClickCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.groupMoaClickCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.totalPrescriptionOpenCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.groupPrescriptionOpenCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.totalPrescriptionClickCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.groupPrescriptionClickCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.totalScheduleOpenCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.groupScheduleOpenCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.totalScheduleClickCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.groupScheduleClickCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.totalDrInfoClickCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.groupDrInfoClickCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.totalClickCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              _vm.grandTotal.groupClickCount
                            )
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }