import { render, staticRenderFns } from "./SampleAskView.vue?vue&type=template&id=148212c4&"
import script from "./SampleAskView.vue?vue&type=script&lang=js&"
export * from "./SampleAskView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("d:\\workspace\\liveinfo3-front-admin-dev\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('148212c4')) {
      api.createRecord('148212c4', component.options)
    } else {
      api.reload('148212c4', component.options)
    }
    module.hot.accept("./SampleAskView.vue?vue&type=template&id=148212c4&", function () {
      api.rerender('148212c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/clients/statistics/durginfo-stast-view/SampleAskView.vue"
export default component.exports